import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Topic } from '../../models/topic';
import { camelize } from '../../shared/camelize';
import { Select, Store } from '@ngxs/store';
import { TopicsState } from '../../state/topics.state';
import { map, Observable } from 'rxjs';
import { UpdateSubscriptionStatus } from '../../state/topics.actions';

@Component({
  selector: 'app-topics-list',
  templateUrl: './topics-list.component.html',
  styleUrls: ['./topics-list.component.scss'],
})
export class TopicsListComponent implements OnInit {
  topicsForm$: Observable<UntypedFormGroup>;
  @Select(TopicsState.topics) topics$: Observable<Topic[]>;
  topicsHighlighted$: Observable<Topic[]>;
  topicsNotHighlighted$: Observable<Topic[]>;

  constructor(private store: Store) {}

  ngOnInit() {
    this.topicsForm$ = this.topics$.pipe(
      map((topics: Topic[]) => {
        const formGroup = new UntypedFormGroup({});
        topics.forEach((topic) => {
          formGroup.addControl(
            this.getFormControlName(topic),
            new UntypedFormControl({
              value: topic.subscribed,
              disabled: topic.mandatory,
            })
          );
        });
        return formGroup;
      })
    );

    this.topicsHighlighted$ = this.filterTopics(this.topics$, true);
    this.topicsNotHighlighted$ = this.filterTopics(this.topics$, false);
  }

  filterTopics(topics$: Observable<Topic[]>, highlighted: boolean) {
    return topics$.pipe(
      map((topics) =>
        topics.filter((topic) => highlighted === topic.highlighted)
      )
    );
  }

  updateSubscriptionStatus(topicId: string, value: boolean) {
    this.store.dispatch(new UpdateSubscriptionStatus(topicId, value));
  }

  getFormControlName(topic: Topic): string {
    return camelize(topic.title);
  }
}

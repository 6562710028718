import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BackendClient } from '../../environments/environment-type';
import { Observable } from 'rxjs';
import { Topic } from '../models/topic';
import { Store } from '@ngxs/store';
import { AppState } from '../state/app.state';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private mdiBackendClient: BackendClient;
  private ticket: string;

  constructor(private httpClient: HttpClient, private store: Store) {
    this.mdiBackendClient = environment.clients.backendClient;
    this.ticket = this.store.selectSnapshot(AppState.ticket);
  }

  submitSubscriptionState(topics: Topic[]): Observable<any> {
    const payload = topics.map((topic) => ({
      id: topic.id,
      subscribed: topic.subscribed,
    }));
    return this.httpClient.post(
      `${this.mdiBackendClient.apiUrl}/subscription/by-ticket/${this.ticket}`,
      payload
    );
  }
}

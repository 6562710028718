<ng-container *ngIf="topicsForm$ | async as topicsForm">
  <section [formGroup]="topicsForm" class="my-5">
    <ng-container *ngIf="(topicsHighlighted$ | async).length > 0">
      <div class="highlighted mb-4">
        <h3 class="mb-4">{{ 'highlighted_topics.intro' | translate }}:</h3>
        <div
          class="mb-3"
          *ngFor="let topicHighlighted of topicsHighlighted$ | async"
        >
          <mat-checkbox
            [attr.data-e2e-subscribed]="topicHighlighted.subscribed"
            [attr.data-e2e-topic-title]="topicHighlighted.title"
            [formControlName]="getFormControlName(topicHighlighted)"
            (change)="
              updateSubscriptionStatus(topicHighlighted.id, $event.checked)
            "
          >
            <h4>
              {{ topicHighlighted.title | translate
              }}<span *ngIf="topicHighlighted.mandatory" class="ms-2">*</span>
            </h4>
            <p>{{ topicHighlighted.description | translate }}</p>
          </mat-checkbox>
        </div>
      </div>
      <h3 class="mb-4">{{ 'other_topics' | translate }}:</h3>
    </ng-container>
    <div
      class="mb-3"
      *ngFor="let topicNotHighlighted of topicsNotHighlighted$ | async"
    >
      <mat-checkbox
        [attr.data-e2e-subscribed]="topicNotHighlighted.subscribed"
        [attr.data-e2e-topic-title]="topicNotHighlighted.title"
        [formControlName]="getFormControlName(topicNotHighlighted)"
        (change)="
          updateSubscriptionStatus(topicNotHighlighted.id, $event.checked)
        "
      >
        <h4>
          {{ topicNotHighlighted.title | translate
          }}<span *ngIf="topicNotHighlighted.mandatory" class="ms-2">*</span>
        </h4>
        <p>{{ topicNotHighlighted.description | translate }}</p>
      </mat-checkbox>
    </div>
  </section>
  <p>* {{ 'mandatory_topics_expl' | translate }}</p>
</ng-container>

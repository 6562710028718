import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { GlobalErrorHandler } from './global-error-handler';
import { HttpClientModule } from '@angular/common/http';
import { TranslatePipe } from './pipes/translate.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const sharedModules = [
  CommonModule,
  HttpClientModule,
  MaterialModule,
  RouterModule,
  ReactiveFormsModule,
  BrowserAnimationsModule,
];
const sharedComponents = [];
const sharedPipes = [TranslatePipe];

@NgModule({
  declarations: [...sharedPipes, ...sharedComponents],
  imports: [...sharedModules],
  exports: [...sharedModules, ...sharedPipes, ...sharedComponents],
  providers: [GlobalErrorHandler],
})
export class SharedModule {}

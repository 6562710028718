import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { LanguageCode } from '../enums/language';
import { Subject, Observable, of, tap, catchError } from 'rxjs';
import { takeUntil, share } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Store } from '@ngxs/store';
import { SetLanguageCode, SetTranslations } from '../state/app.actions';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  private httpClient: HttpClient;
  private _currentLanguage: LanguageCode;
  private _translations = {};
  private _destroy$: Subject<boolean> = new Subject();

  get currentLanguageCode(): LanguageCode {
    return this._currentLanguage;
  }

  constructor(
    handler: HttpBackend,
    private store: Store,
    private title: Title
  ) {
    // avoid jwt token intercepting in APP_INITIALIZER
    this.httpClient = new HttpClient(handler);
  }

  init(): Observable<any> {
    this._currentLanguage = this.getCurrentLanguageCodeFromStore();

    const { apiUrl, appId, env } = environment.clients.dictionaryServiceClient;

    return this.httpClient
      .get<any>(`${apiUrl}/v3/applications/${appId}/env/${env}/translations`)
      .pipe(
        takeUntil(this._destroy$),
        share(),
        tap((response: any) => {
          this._translations = response;
          this.store.dispatch(new SetTranslations(this._translations));
        }),
        catchError((error) => {
          console.error('Error while retrieving translations.');
          this._translations = {};
          return of(error);
        })
      );
  }

  getTranslation(translationKey: string): string | void {
    if (!translationKey) return;

    translationKey = translationKey.toLowerCase();
    return (
      (this._translations[translationKey] &&
        (this._translations[translationKey][this._currentLanguage] ||
          // fallback to English
          this._translations[translationKey][LanguageCode.en])) ||
      // fallback to translation key
      translationKey
    );
  }

  useLanguage(languageCode: LanguageCode): void {
    this._currentLanguage = languageCode;
    this.store.dispatch(new SetLanguageCode(languageCode));
    this.translatePageTitle();
  }

  getCurrentLanguageCodeFromStore(): LanguageCode {
    return this.store.selectSnapshot((state) => state.app?.languageCode);
  }

  unsubscribeInitListener(): void {
    this._destroy$.next(true);
  }

  public translatePageTitle(): void {
    const pageTitle: string | void = this.getTranslation('page_title');
    if (pageTitle) this.title.setTitle(pageTitle);
  }
}

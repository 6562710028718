import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import {
  SetLanguageCode,
  SetLoading,
  SetSubmitted,
  SetTicket,
  SetTranslations,
} from './app.actions';
import { LanguageCode } from '../enums/language';

export interface AppStateModel {
  loading: boolean;
  submitted: boolean;
  ticket: string;
  translations: any;
  languageCode: LanguageCode;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    loading: false,
    submitted: false,
    ticket: '',
    translations: null,
    languageCode: LanguageCode.en,
  },
})
@Injectable()
export class AppState {
  @Selector()
  static loading(state: AppStateModel) {
    return state.loading;
  }

  @Action(SetLoading)
  setLoading(ctx: StateContext<AppStateModel>, { loading }: SetLoading) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      loading,
    });
  }

  @Selector()
  static submitted(state: AppStateModel) {
    return state.submitted;
  }

  @Action(SetSubmitted)
  setSubmitted(ctx: StateContext<AppStateModel>, { submitted }: SetSubmitted) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      submitted,
    });
  }

  @Selector()
  static ticket(state: AppStateModel) {
    return state.ticket;
  }

  @Action(SetTicket)
  setTicket(ctx: StateContext<AppStateModel>, { ticket }: SetTicket) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      ticket,
    });
  }

  @Selector()
  static translations(state: AppStateModel) {
    return state.translations;
  }

  @Action(SetTranslations)
  setTranslations(
    ctx: StateContext<AppStateModel>,
    { translations }: SetTranslations
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      translations,
    });
  }

  @Selector()
  static languageCode(state: AppStateModel) {
    return state.languageCode;
  }

  @Action(SetLanguageCode)
  setLanguageCode(
    ctx: StateContext<AppStateModel>,
    { languageCode }: SetLanguageCode
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      languageCode,
    });
  }
}

import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  override handleError(error): void {
    /* Log error to Sentry */
    Sentry.captureException(error);
  }
}

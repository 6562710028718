import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { BackendClient } from '../../environments/environment-type';
import { Store } from '@ngxs/store';
import { User } from '../models/user';
import { Topic } from '../models/topic';
import { SetTopics } from '../state/topics.actions';
import { SetUser } from '../state/user.actions';
import { SetTicket } from '../state/app.actions';
import * as Sentry from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  private mdiBackendClient: BackendClient;

  constructor(private httpClient: HttpClient, private store: Store) {
    this.mdiBackendClient = environment.clients.backendClient;
  }

  resolveTicket(): Observable<any> {
    const urlQueryParams = new URLSearchParams(window.location.search);
    const ticket = urlQueryParams.get('ticket');

    if (!ticket) {
      Sentry.captureException(new Error('JWT ticket missing'));
      return of({});
    }

    this.store.dispatch(new SetTicket(ticket));

    return this.httpClient
      .get(`${this.mdiBackendClient.apiUrl}/subscription/by-ticket/${ticket}`)
      .pipe(
        tap((response: any) => {
          const user = this.mapUser(response);
          const topics =
            response.topics?.length > 0
              ? response.topics.map((topic) => this.mapTopic(topic))
              : [];
          this.store.dispatch([new SetUser(user), new SetTopics(topics)]);
        }),
        catchError((err) => {
          Sentry.captureException(err);
          return of({});
        })
      );
  }

  mapUser(data: any): User {
    return {
      firstName: data.firstName || null,
      lastName: data.lastName || null,
      email: data.email || null,
    };
  }

  mapTopic(data: any): Topic {
    return {
      id: data.id || null,
      title: data.name ? 'subscription_topic.' + data.name + '.title' : null,
      description: data.name
        ? 'subscription_topic.' + data.name + '.desc'
        : null,
      mandatory: data.mandatory,
      subscribed: data.subscribed,
      highlighted: data.highlighted,
    };
  }
}

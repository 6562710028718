import { Component, OnInit } from '@angular/core';
import { LanguageCode, LanguageCodeToLanguageMap } from './enums/language';
import { TranslateService } from './services/translate.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { AppState, AppStateModel } from './state/app.state';
import { User } from './models/user';
import {
  UnsubscribeAll,
  UpdateSubscriptionStatus,
} from './state/topics.actions';
import { TopicsState } from './state/topics.state';
import { Topic } from './models/topic';
import { UserState } from './state/user.state';
import { SubscriptionService } from './services/subscription.service';
import { SetSubmitted } from './state/app.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  languageCodeToLanguageMap = LanguageCodeToLanguageMap;
  get languageCodes(): LanguageCode[] {
    return Object.values(LanguageCode);
  }

  @Select(AppState) appState$: Observable<AppStateModel>;
  @Select(UserState.user) user$: Observable<User>;
  @Select(TopicsState.topics) topics$: Observable<Topic[]>;

  constructor(
    public translateService: TranslateService,
    private route: ActivatedRoute,
    private store: Store,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit() {
    // read language code from url query param
    this.route.queryParams.subscribe((params) => {
      const urlLanguageCode = params['lang'];
      if (this.languageCodes.includes(urlLanguageCode)) {
        this.translateService.useLanguage(urlLanguageCode);
      }
    });
    this.translateService.translatePageTitle();
  }

  submit(): void {
    const topics = this.store.selectSnapshot(TopicsState.topics);
    this.subscriptionService
      .submitSubscriptionState(topics)
      .subscribe((response: any) => {
        const mappedResponse: { topicId: string; subscribed: boolean }[] =
          response.map((responseItem) => ({
            topicId: responseItem.id,
            subscribed: responseItem.subscribed,
          }));
        // apply backend response to frontend state to stay in sync
        mappedResponse.forEach((mappedResponseItem) => {
          this.store.dispatch(
            new UpdateSubscriptionStatus(
              mappedResponseItem.topicId,
              mappedResponseItem.subscribed
            )
          );
        });
        this.store.dispatch(new SetSubmitted(true));
      });
  }

  unsubscribeFromAll(): void {
    this.store.dispatch(new UnsubscribeAll());
  }

  onLanguageChange(languageCode): void {
    this.translateService.useLanguage(languageCode);
  }
}

<mat-menu #language="matMenu" class="header-menu">
  <button
    [class.active]="translateService.currentLanguageCode === languageCode"
    mat-menu-item
    (click)="onLanguageChange(languageCode)"
    *ngFor="let languageCode of languageCodes"
  >
    <span>{{ languageCodeToLanguageMap.get(languageCode) }}</span>
  </button>
</mat-menu>
<mat-toolbar class="d-flex justify-content-between">
  <img class="akelius-logo" src="assets/akelius_logo.svg" alt="Akelius Logo" />
  <button
    mat-icon-button
    class="header-button"
    aria-label="Language"
    [matMenuTriggerFor]="language"
  >
    <mat-icon svgIcon="ak-globe-web" class="large-icon"></mat-icon>
  </button>
</mat-toolbar>
<div class="container my-4">
  <ng-container *ngIf="(appState$ | async).loading; else ready">
    <div class="d-flex justify-content-center">
      <mat-spinner></mat-spinner>
    </div>
  </ng-container>
  <ng-template #ready>
    <ng-container *ngIf="user$ | async as user; else noUser">
      <h1>{{ 'heading' | translate }}</h1>
      <h2 class="mt-3 mb-2">
        {{ 'for' | translate }} {{ (user$ | async).email }}
      </h2>
      <ng-container *ngIf="!(appState$ | async).submitted; else submitted">
        <div>{{ 'intro_text' | translate }}</div>
        <app-topics-list></app-topics-list>
        <div class="w-100 d-flex justify-content-between align-items-baseline">
          <a [routerLink]="" (click)="unsubscribeFromAll()">{{
            'unsubscribe_all' | translate
          }}</a>
          <button mat-flat-button color="primary" (click)="submit()">
            {{ 'submit' | translate }}
          </button>
        </div>
      </ng-container>
      <ng-template #submitted>
        <app-confirmation></app-confirmation>
      </ng-template>
    </ng-container>
    <ng-template #noUser>
      <p class="mt-3">{{ 'no_user' | translate }}</p>
    </ng-template>
  </ng-template>
</div>

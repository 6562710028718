import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { User } from '../models/user';
import { SetUser } from './user.actions';

export interface UserStateModel {
  user: User;
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    user: null,
  },
})
@Injectable()
export class UserState {
  @Selector()
  static user(state: UserStateModel) {
    return state.user;
  }

  @Action(SetUser)
  setUser(ctx: StateContext<UserStateModel>, { user }: SetUser) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      user,
    });
  }
}

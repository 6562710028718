import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalErrorHandler } from './global-error-handler';
import { SharedModule } from './shared.module';
import { IconService } from './services/icon.service';
import { TopicsListComponent } from './components/topics-list/topics-list.component';
import { TranslateService } from './services/translate.service';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../environments/environment';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { AppState } from './state/app.state';
import { TicketService } from './services/ticket.service';
import { TopicsState } from './state/topics.state';
import { UserState } from './state/user.state';
import { forkJoin } from 'rxjs';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import * as Sentry from '@sentry/angular-ivy';

const initFactory =
  (
    iconService: IconService,
    translateService: TranslateService,
    ticketService: TicketService
  ) =>
  () => {
    /* Sync */
    // initialize sentry
    Sentry.init({
      ...environment.sentry,
      environment: environment.name,
    });
    iconService.registerIcons();
    /* Async */
    return forkJoin([translateService.init(), ticketService.resolveTicket()]);
  };

@NgModule({
  declarations: [AppComponent, TopicsListComponent, ConfirmationComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    NgxsModule.forRoot([AppState, UserState, TopicsState], {
      developmentMode: !environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initFactory,
      multi: true,
      deps: [IconService, TranslateService, TicketService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
